import {
  formatDistanceStrict as dfFormatDistanceStrict,
  formatDistanceToNowStrict as dfFormatDistanceToNowStrict,
} from "date-fns"
import { Temporal } from "temporal-polyfill"

import { PlainDateRange } from "types/dates"

import {
  TimezoneContext,
  getDefaultTimezone,
  getTimezoneFromContext,
} from "./timezone-utils"

// 2025-03-06: When migrating to Temporal, we replaced a bunch of moment/date-fns
// format strings with calls to toLocaleString(). To preserve existing behavior, for now
// I'm hardcoding en-GB, so we get results very similar to our previous output. But it's
// worth thinking about whether that's what we want long-term.
export const FORMAT_LOCALE = "en-GB"

export function formatDistance(
  from: Temporal.Instant,
  to: Temporal.Instant,
  options?: {
    includeSeconds?: boolean
    addSuffix?: boolean
    locale?: Locale
  }
): string {
  return dfFormatDistanceStrict(
    from.epochMilliseconds,
    to.epochMilliseconds,
    options
  )
}

export function formatDistanceToNow(
  date: Temporal.Instant,
  options?: {
    includeSeconds?: boolean
    addSuffix?: boolean
    locale?: Locale
  }
): string {
  return dfFormatDistanceToNowStrict(date.epochMilliseconds, options)
}

// This is a bit of a hack.
//
// The output of toLocaleString() varies a bit between browsers, and in many cases
// includes commas in the date format where we didn't previously have any. Perhaps
// we would be okay with this, but the behavior is inconsistent between browsers, and
// even inconsistent between Node on my machine and Node in CI. That last bit is the
// killer, because it makes tests very annoying to write.
//
// In an ideal world I'd like to figure out how to make Node behave consistently, then
// get rid of this and live with the commas in some browsers. But for now, this'll do.
export const normalizeFormattedDate = (date: string) => date.replaceAll(",", "")

function makePlainDateFormatter(format: Intl.DateTimeFormatOptions) {
  return (date: Temporal.PlainDate) =>
    normalizeFormattedDate(date.toLocaleString(FORMAT_LOCALE, format))
}

export const formatPlainDateAsFull = makePlainDateFormatter({
  weekday: "long",
  day: "numeric",
  month: "long",
  year: "numeric",
})
export const formatPlainDateAsAbbreviated = makePlainDateFormatter({
  weekday: "short",
  day: "numeric",
  month: "short",
  year: "numeric",
})
export const formatPlainDateAsMinimized = makePlainDateFormatter({
  day: "numeric",
  month: "short",
  year: "numeric",
})
export const formatPlainDateAsMedium = makePlainDateFormatter({
  weekday: "short",
  day: "numeric",
  month: "short",
})
export const formatPlainDateAsShort = makePlainDateFormatter({
  month: "short",
  day: "numeric",
})

function makeInstantFormatter(format: Intl.DateTimeFormatOptions) {
  return (date: Temporal.Instant, zone: TimezoneContext) =>
    normalizeFormattedDate(
      date
        .toZonedDateTimeISO(getTimezoneFromContext(zone))
        .toLocaleString(FORMAT_LOCALE, format)
    )
}

// These names make very little sense, but they match the old Moment-based helpers.
export const formatInstantAsDate = makeInstantFormatter({
  weekday: "long",
  day: "numeric",
  month: "long",
  year: "numeric",
})
export const formatInstantAsAbbreviatedDate = makeInstantFormatter({
  weekday: "short",
  month: "short",
  day: "numeric",
  year: "numeric",
})
export const formatInstantAsMinimizedDate = makeInstantFormatter({
  month: "short",
  day: "numeric",
  year: "numeric",
})
export const formatInstantAsMediumDate = makeInstantFormatter({
  weekday: "short",
  day: "numeric",
  month: "short",
})
export const formatInstantAsShortDate = makeInstantFormatter({
  day: "numeric",
  month: "long",
})
export const formatInstantAsDay = makeInstantFormatter({
  weekday: "short",
})
const _formatInstantAsDateForDateTimeTime = makeInstantFormatter({
  day: "numeric",
  month: "long",
  year: "numeric",
})
export const formatInstantAsDateTime = (
  time: Temporal.Instant,
  zone: TimezoneContext
) =>
  // Bespoke hackery to get the "time on date" format rather than "date at time"
  `${formatInstantAsTime(time, zone)} on ${_formatInstantAsDateForDateTimeTime(time, zone)}`
export const formatInstantAsPreciseDateTime = (
  time: Temporal.Instant,
  zone: TimezoneContext
) =>
  `${formatInstantAsPreciseTime(time, zone)} on ${_formatInstantAsDateForDateTimeTime(time, zone)}`
export const formatInstantAsPreciseDateTimeWithZone = makeInstantFormatter({
  weekday: "short",
  day: "numeric",
  month: "long",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  timeZoneName: "short",
  hour12: false,
})
export const formatInstantAsAbbreviatedDateTime = (
  time: Temporal.Instant,
  zone: TimezoneContext
) =>
  `${formatInstantAsTime(time, zone)} on ${formatInstantAsAbbreviatedDate(time, zone)}`
export const formatInstantAsShortDateTime = makeInstantFormatter({
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
})
export const formatInstantAsShortDateTimeWithYear = makeInstantFormatter({
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
})
export const formatInstantAsTime = makeInstantFormatter({
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
})
export const formatInstantAsPreciseTime = makeInstantFormatter({
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false,
})

export function formatInstantsAsShortRange(
  a: Temporal.Instant,
  b: Temporal.Instant,
  context: TimezoneContext
): string {
  const zone = getTimezoneFromContext(context)
  return `${a.toZonedDateTimeISO(zone).toLocaleString(FORMAT_LOCALE, {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })} - ${b.toZonedDateTimeISO(zone).toLocaleString(FORMAT_LOCALE, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })}`
}

export function formatInstantsAsLongRange(
  start: Temporal.Instant,
  end: Temporal.Instant,
  context: TimezoneContext
): string {
  const zone = getTimezoneFromContext(context)
  if (
    dateContainingInstant(start, zone).equals(dateContainingInstant(end, zone))
  ) {
    // On same date
    return `from ${formatInstantAsTime(start, zone)} to ${formatInstantAsTime(end, zone)} on ${formatInstantAsAbbreviatedDate(start, zone)}`
  } else {
    // Different dates
    return `from ${formatInstantAsTime(start, zone)} on ${formatInstantAsAbbreviatedDate(start, zone)}
      to ${formatInstantAsTime(end, zone)} on ${formatInstantAsAbbreviatedDate(end, zone)}`
  }
}

// Convert a duration into a "4h 23m" style string. Not recommended for durations likely to be over 24 hours.
export const formatShortDuration = (
  duration: Temporal.Duration,
  shorthand = true
): string => {
  const minutes = duration.total("minutes")
  let minutesSymbol = "m"
  let hoursSymbol = "h"
  if (!shorthand) {
    minutesSymbol = " minute"
    hoursSymbol = " hour"
  }
  if (minutes < 60) {
    return `${minutes}${minutesSymbol}`
  } else {
    const hours = Math.floor(minutes / 60)
    const minutes_remainder = minutes % 60
    return `${hours}${hoursSymbol} ${minutes_remainder}${minutesSymbol}`
  }
}

// set aside for mocking
export function getCurrentInstant(): Temporal.Instant {
  return Temporal.Now.instant()
}

function genericCompare(
  a: Temporal.Instant | Temporal.PlainDate,
  b: Temporal.Instant | Temporal.PlainDate
): -1 | 0 | 1 {
  if (!a || !b) {
    throw new Error("null in genericCompare")
  }
  if (a instanceof Temporal.Instant) {
    return Temporal.Instant.compare(a, b as Temporal.Instant)
  } else if (a instanceof Temporal.PlainDate) {
    return Temporal.PlainDate.compare(a, b as Temporal.PlainDate)
  }
  throw new TypeError(
    `Trying to compare ${a} (${typeof a}) using temporal-utils`
  )
}

export function isBefore(a: Temporal.Instant, b: Temporal.Instant): boolean
export function isBefore(a: Temporal.PlainDate, b: Temporal.PlainDate): boolean
export function isBefore(
  a: Temporal.Instant | Temporal.PlainDate,
  b: Temporal.Instant | Temporal.PlainDate
): boolean {
  return genericCompare(a, b) == -1
}

export function isBeforeOrEqual(
  a: Temporal.Instant,
  b: Temporal.Instant
): boolean
export function isBeforeOrEqual(
  a: Temporal.PlainDate,
  b: Temporal.PlainDate
): boolean
export function isBeforeOrEqual(
  a: Temporal.Instant | Temporal.PlainDate,
  b: Temporal.Instant | Temporal.PlainDate
): boolean {
  return genericCompare(a, b) <= 0
}

export function isAfter(a: Temporal.Instant, b: Temporal.Instant): boolean
export function isAfter(a: Temporal.PlainDate, b: Temporal.PlainDate): boolean
export function isAfter(
  a: Temporal.Instant | Temporal.PlainDate,
  b: Temporal.Instant | Temporal.PlainDate
): boolean {
  return genericCompare(a, b) == 1
}

export function isAfterOrEqual(
  a: Temporal.Instant,
  b: Temporal.Instant
): boolean
export function isAfterOrEqual(
  a: Temporal.PlainDate,
  b: Temporal.PlainDate
): boolean
export function isAfterOrEqual(
  a: Temporal.Instant | Temporal.PlainDate,
  b: Temporal.Instant | Temporal.PlainDate
): boolean {
  return genericCompare(a, b) >= 0
}

export function durationIsLess(
  a: Temporal.Duration,
  b: Temporal.Duration
): boolean {
  return Temporal.Duration.compare(a, b) == -1
}

export function durationIsGreater(
  a: Temporal.Duration,
  b: Temporal.Duration
): boolean {
  return Temporal.Duration.compare(a, b) == 1
}

export function startOfDay(
  day: Temporal.PlainDate,
  zone: TimezoneContext
): Temporal.Instant {
  return day.toZonedDateTime(getTimezoneFromContext(zone)).toInstant()
}

export function endOfDay(
  day: Temporal.PlainDate,
  zone: TimezoneContext
): Temporal.Instant {
  return startOfDay(day.add({ days: 1 }), zone).subtract({ nanoseconds: 1 })
}

export function min(
  ...args: (Temporal.Instant | null | undefined)[]
): Temporal.Instant
export function min(
  ...args: (Temporal.PlainDate | null | undefined)[]
): Temporal.PlainDate
export function min(
  ...args: (Temporal.Instant | Temporal.PlainDate | null | undefined)[]
): Temporal.Instant | Temporal.PlainDate {
  return args
    .filter((x): x is Temporal.Instant | Temporal.PlainDate => x != null)
    .reduce((a, b) => (genericCompare(a, b) == -1 ? a : b))
}

export function max(
  ...args: (Temporal.Instant | null | undefined)[]
): Temporal.Instant
export function max(
  ...args: (Temporal.PlainDate | null | undefined)[]
): Temporal.PlainDate
export function max(
  ...args: (Temporal.Instant | Temporal.PlainDate | null | undefined)[]
): Temporal.Instant | Temporal.PlainDate {
  return args
    .filter((x): x is Temporal.Instant | Temporal.PlainDate => x != null)
    .reduce((a, b) => (genericCompare(a, b) == 1 ? a : b))
}

// Given two instants that may be null, return true if both are equal, or both are null.
export function nullableInstantsEqual(
  a: Temporal.Instant | null | undefined,
  b: Temporal.Instant | null | undefined
) {
  if (a == null && b == null) {
    return true
  } else if (a == null || b == null) {
    return false
  }
  {
    return a.equals(b)
  }
}

export function formatAsRelativeTime(
  actualDate: Temporal.Instant,
  scheduledDate: Temporal.Instant
) {
  const secsLate = Math.abs(scheduledDate.until(actualDate).total("seconds"))
  const absSecsLate = Math.abs(secsLate)
  const typeDescription = isBefore(actualDate, scheduledDate) ? "early" : "late"

  if (secsLate < 10 && secsLate > -10) {
    return "on time"
  } else if (absSecsLate < 60) {
    // Up to a minute
    return `${Math.round(absSecsLate)} sec${absSecsLate == 1 ? "" : "s"} ${typeDescription}`
  } else if (absSecsLate < 60 * 60) {
    // Up to an hour
    const mins = Math.floor(absSecsLate / 60)
    return `${mins} min${mins == 1 ? "" : "s"} ${typeDescription}`
  } else if (absSecsLate < 60 * 60 * 24) {
    // Up to a day
    const hoursAmount = Math.floor(absSecsLate / 60 / 60)
    const minsAmount = Math.floor(absSecsLate / 60) - 60 * hoursAmount
    return `${hoursAmount} hr${hoursAmount == 1 ? "" : "s"} ${minsAmount} min${
      minsAmount == 1 ? "" : "s"
    } ${typeDescription}`
  } else {
    const daysAmount = Math.floor(absSecsLate / 60 / 60 / 24)
    const hoursAmount = Math.floor(absSecsLate / 60 / 60) - 24 * daysAmount
    return `${daysAmount} day${daysAmount == 1 ? "" : "s"} ${hoursAmount} hr${
      hoursAmount == 1 ? "" : "s"
    } ${typeDescription}`
  }
}

export const getDateArray = (
  dateRange: PlainDateRange
): Temporal.PlainDate[] => {
  if (isAfter(dateRange.start, dateRange.end)) return []
  const result = [dateRange.start]
  while (!result[result.length - 1].equals(dateRange.end)) {
    result.push(result[result.length - 1].add({ days: 1 }))
  }
  return result
}

export function dateContainingInstant(
  instant: Temporal.Instant,
  zone: TimezoneContext
): Temporal.PlainDate {
  return instant.toZonedDateTimeISO(getTimezoneFromContext(zone)).toPlainDate()
}

// Max date is an optional ISO date string when you don't want to allow bookings after
// This criteria will be applied on top of normal criteria of bookings can't be after the
// result of getLatestBookableDateOverall()
export const bookingToDate = (
  maxDate?: Temporal.PlainDate
): Temporal.PlainDate => {
  const bookingsOpenUntil = getLatestBookableDateOverall()
  if (maxDate == null) {
    return bookingsOpenUntil
  } else {
    return min(bookingsOpenUntil, maxDate)
  }
}

// Min date is an optional ISO date string when you don't want to allow bookings before
// This criteria will be applied on top of normal criteria of bookings can't be before
// today in UK time
export const bookingFromDate = (
  minDate?: Temporal.PlainDate
): Temporal.PlainDate => {
  const today = Temporal.Now.plainDateISO(getDefaultTimezone())
  if (minDate == null) {
    return today
  } else {
    return max(today, minDate)
  }
}

// This is set aside so that it can be mocked in tests
export const getLatestBookableDateOverall = () =>
  Temporal.PlainDate.from("2025-06-29")

// Rota is only accurate from this date
export const driverRotaPublishedFromDate = (): Temporal.PlainDate => {
  return Temporal.PlainDate.from("2022-02-21")
}

// Rota is only published until this date. Assignment may change beyond this with no notice
export const driverRotaPublishedToDate = (): Temporal.Instant => {
  // TODO: is this even still used?
  const value = dateContainingInstant(
    getCurrentInstant(),
    getDefaultTimezone()
  ).add({ days: 27 })
  return value
    .add({ days: 7 - value.dayOfWeek })
    .toZonedDateTime({ timeZone: getDefaultTimezone(), plainTime: "23:59:00" })
    .toInstant()
}

export const formatInstantAsShortRelativeDateTime = (
  instant: Temporal.Instant,
  context: TimezoneContext,
  options: {
    // shortening to just the weekday (e.g. "Wed at 12:00") is only recommended in contexts where
    // all dates are in the past (e.g. chat messages). If dates are a mix of past and future dates,
    // displaying them as just the weekday and time confuses people.
    allowShorteningToJustWeekday?: boolean
    // If we would output just the time, output "Today at [time]" instead
    specifyToday?: boolean
  } = {}
): string => {
  let format: Intl.DateTimeFormatOptions
  let prefix = ""
  const nowDate = dateContainingInstant(Temporal.Now.instant(), context)
  const date = dateContainingInstant(instant, context)
  if (date.equals(nowDate)) {
    format = { hour: "2-digit", minute: "2-digit", hour12: false }
    if (options.specifyToday) {
      prefix = "Today at "
    }
  } else if (
    date.weekOfYear == nowDate.weekOfYear &&
    date.yearOfWeek == nowDate.yearOfWeek &&
    options?.allowShorteningToJustWeekday
  ) {
    format = {
      weekday: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }
  } else if (date.year == nowDate.year) {
    // NB when you're in January this will put the year on December dates, even though they're
    // recent. We could decide to display the year on dates that are > 365 days in the past instead
    format = {
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }
  } else {
    format = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }
  }
  return (
    prefix +
    normalizeFormattedDate(
      instant
        .toZonedDateTimeISO(getTimezoneFromContext(context))
        .toLocaleString(FORMAT_LOCALE, format)
    )
  )
}
